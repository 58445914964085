import { forwardRef } from 'react';
import { card, cardHoverable } from './card.css';
import { clsx } from 'clsx';

export const LayoutCard = forwardRef<
  HTMLDivElement,
  { hoverable?: boolean } & React.ComponentPropsWithoutRef<'div'>
>(function LayoutCard({ className, hoverable, ...rest }, ref) {
  return (
    <div
      ref={ref}
      className={clsx(card, hoverable && cardHoverable, className)}
      {...rest}
    ></div>
  );
});
